/**
 * IF A COLOR IS CHANGED IT MUST ALSO BE CHANGED IN THE SCRATCHCARD-BACKOFFICE
 * look up Colors.ts in scratchcard-backoffice
 */

export enum ScratchcardColors {
  EasyBlue100 = '#C5EEFF',
  EasyBlue800 = '#016DBF',
  FieryRed300 = '#FF9FC0',
  FieryRed700 = '#DC0152',
  FieryRed900 = '#7D1135',
  HappyYellow400 = '#FFE35C',
  LuckyGreen400 = '#2EE8A2',
  LuckyGreen900 = '#005334',
  TrustBlue900 = '#000860',
}
