<template>
  <figure class="scratchcard-display rounded-md m-none" :style="theme">
    <Component
      v-bind="{ ...$attrs, ...presentation.props, height, width }"
      :is="presentation.component"
      :alt="altText"
      class="rounded-md"
    />
    <div
      v-if="isCaptionVisible && price"
      class="price rounded-md text-center text-white py-4xs px-2xs font-medium"
      :class="largeCaptionClass"
    >
      {{ formatMoney(price, { fractionDigits: 0 }) }}
    </div>

    <figcaption
      v-show="isCaptionVisible"
      class="caption-wrap flex justify-center"
    >
      <span
        class="caption rounded-md text-center text-white leading-sm py-3xs px-xs"
      >
        <slot name="caption">
          <div
            class="caption-name font-medium text-uppercase"
            :class="largeCaptionClass"
          >
            {{ name }}
          </div>

          <div v-if="jackpot" class="caption-amount" :class="smallCaptionClass">
            {{
              $t('scratchcard.teaser.button.winupto', {
                amount: formatMoney(jackpot, { fractionDigits: 0 }),
              })
            }}
          </div>
        </slot>
      </span>
    </figcaption>

    <slot />
  </figure>
</template>

<script lang="ts" setup>
import { LcImage } from '@lottocom/frontend-components'

import { ScratchcardDisplayFallback } from '#components'
import { type Money } from '~/@types/generated/backend/graphql-schema-types'
import { ScratchcardColors } from '~/constants/scratchcardColors'

type ScratchardDisplayProps = {
  fontSize?: 'lg' | 'md'
  height?: number
  isCaptionVisible?: boolean
  jackpot?: Maybe<Money>
  name: string
  price?: Maybe<Money>
  primaryColor?: Maybe<string>
  secondaryColor?: Maybe<string>
  src?: Maybe<string>
  width?: number
}

const { t } = useI18n()

const props = withDefaults(defineProps<ScratchardDisplayProps>(), {
  fontSize: 'md',
  height: 260,
  isCaptionVisible: false,
  isOutlined: false,
  primaryColor: ScratchcardColors.TrustBlue900,
  secondaryColor: ScratchcardColors.FieryRed700,
  src: null,
  width: 260,
})

const presentation = computed(() => {
  if (props.src) {
    return {
      component: LcImage,
      props: {
        preload: true,
        src: props.src,
      },
    }
  }

  return {
    component: ScratchcardDisplayFallback,
    props: { title: props.name },
  }
})
const theme = computed(() => ({
  '--primary-scratchcard-color':
    props.primaryColor ?? ScratchcardColors.TrustBlue900,
  '--secondary-scratchcard-color':
    props.secondaryColor ?? ScratchcardColors.FieryRed700,
}))

const altText = computed(() =>
  props.price
    ? t('scratchcard.teaser.image.alt.with.price', {
        name: props.name,
        price: formatMoney(props.price, { fractionDigits: 0 }),
      })
    : t('scratchcard.teaser.image.alt.without.price', { name: props.name }),
)

const largeCaptionClass = computed(() => ({
  'text-h5': props.fontSize === 'lg',
  'text-md': props.fontSize === 'md',
}))

const smallCaptionClass = computed(() => ({
  'text-md': props.fontSize === 'lg',
  'text-sm': props.fontSize === 'md',
}))
</script>

<style lang="scss" scoped>
$element-position: 8%;

.scratchcard-display {
  position: relative;
  width: 100%;
  box-shadow:
    0.1875rem 0.0625rem 0.125rem rgba(color('secondary900'), 0.1),
    0 0.375rem 0.625rem rgba(color('secondary900'), 0.1);

  &.outlined {
    border: 0.0625rem solid color('secondary400');
  }

  .price {
    position: absolute;
    top: $element-position;
    left: $element-position;
    border: var(--secondary-scratchcard-color) 0.125rem solid;
    background: var(--primary-scratchcard-color);
  }

  .caption {
    border: var(--secondary-scratchcard-color) 0.125rem solid;
    background: var(--primary-scratchcard-color);
    font-size: $font-size-h5;
    overflow-wrap: break-word;

    &-wrap {
      position: absolute;
      right: $element-position;
      bottom: $element-position;
      left: $element-position;
    }
  }

  .caption-amount {
    opacity: 0.8;
  }
}
</style>
